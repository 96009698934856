
import { Component, Prop, Vue } from "vue-property-decorator";
import CloudActivationBackground from "@/components/landingPages/cloudActivation/CloudActivationBackground.vue";

@Component({
  name: "ProductActivationViaCreditThanks",
  components: { CloudActivationBackground },
})
export default class ProductActivationViaCreditThanks extends Vue {
  @Prop() private platformUrl!: string;
  result = {
    hasError: false,
    title: "Verification completed successfully",
    description: "Pipelines activation will take a few minutes",
  };

  mounted() {
    this.$jfNotification.clearAll();
    if (location.href.indexOf("action=invalid-token") > -1) {
      this.result = { hasError: true, title: "Invalid token", description: "Please try the activation process again" };
    }
    if (location.href.indexOf("action=already-exists") > -1) {
      this.result = { hasError: true, title: "Pipelines already exists", description: "no need to activate again" };
    }
  }

  get iconSrc() {
    try {
      return this.$jfImages.get("check.svg");
    } catch (e) {
      this.$log.error("Failed to retrieve icon : check.svg");
    }
  }

  get isMobile() {
    return this.$mq === "mobile";
  }

  get wrapperClasses() {
    const classes: string[] = [this.$mq];
    if (this.isMobile) {
      classes.push("fxCol");
    }
    return classes;
  }
  goToPlatform() {
    window.location.replace(this.platformUrl);
  }
}
